import React, { Component } from "react";

import socialImageDefault from "config/default-social-image";

//components
import Page from "components/Page";
import HeaderComponent from "components/Header";

//sections
import BasicReactTopicsSection from "components/BasicReactTopicsSection";
import TrainerSection from "components/TrainerSection";
import FooterSection from "components/FooterSection";
import WorkshopTypeSection from "components/WorkshopTypeSection";
import FaqViewComponent from "components/FaqView";
import Layout from "components/Layout";

class Workshop extends Component {
  render() {
    const { pageContext = {} } = this.props;
    const { workshopType, settings } = pageContext;

    if (!workshopType) {
      return null;
    }

    const {
      topics,
      prerequisite,
      questions,
      title,
      description
    } = workshopType;

    return (
      <Layout>
        <Page
          pageTitle="React Academy Workshop"
          title={`${title} Workshop by React Academy`}
          description={description}
          url={`https://www.reactacademy.io/workshop-type/${workshopType.slug}`}
          imageUrl={socialImageDefault}
        >
          <HeaderComponent />
          <WorkshopTypeSection workshopType={workshopType} />
          <BasicReactTopicsSection topics={topics} pre={prerequisite} />
          <FaqViewComponent questions={questions} />
          <TrainerSection instructor={settings.instructor} />
          <FooterSection />
        </Page>
      </Layout>
    );
  }
}

export default Workshop;
