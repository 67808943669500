import React, { Component } from 'react';

//styles
import * as S from './styles';
import * as A from 'styles/shared-components';


class WorkshopTypeSection extends Component {
  render() {
    const { workshopType } = this.props;
    const { title, description } = workshopType;

    return (
      <S.WorkshopTypeSection>
        <A.Vertical center>
          <A.Title white> {title} </A.Title>
          <A.Subtitle white>{description}</A.Subtitle>
        </A.Vertical>
        <A.WhiteButton
          href={`https://reactacademy.io/workshop-type/${
            workshopType.slug
          }/printable`}
          target="_blank"
          rel="noopener"
        >
          Printable version
        </A.WhiteButton>
      </S.WorkshopTypeSection>
    );
  }
}

export default (WorkshopTypeSection);
