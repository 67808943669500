import emotion from '@emotion/styled';
import flex from 'styles/flex';

import {
  Section,
  Title as $Title,
  Subtitle as $Subtitle
} from 'styles/shared-components';

export const WorkshopTypeSection = emotion(Section)({
  position: 'relative',
  backgroundColor: '#678fa4',
  color: 'white',
  ...flex.vertical,
  ...flex.centerVertical
});

export const Title = emotion($Title)({
  color: 'white'
});

export const Subtitle = emotion($Subtitle)({
  color: 'white'
});
